import React, {useEffect, useState} from "react";
import {Box, Dialog, Slide} from "@mui/material";
import QuestionDialogContent from "./QuestionDialogContent";
import {LogoBox} from "../VoteContent";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Question({idPlaying,url, user, event, theme}){

    const [open, setOpen] = useState(false)

    useEffect(()=> {
        const handleNewId = async ()=> {
            handleClose()
            setTimeout(() => {
                idPlaying === '' ? handleClose() : handleClickOpen()
            },300)
        }
        handleNewId().catch(console.error)
    },[idPlaying])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <Dialog fullScreen open={true/*open*/} hideBackdrop={true} disableEscapeKeyDown={true} /*TransitionComponent={Transition} */onClose={handleClose}>
            <Box pt={3}></Box>
            <LogoBox logo={event.data().logoUrl ? event.data().logoUrl : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>
            {open &&<QuestionDialogContent event={event} idPlaying={idPlaying} url={url} user={user} theme={theme}/>}
        </Dialog>
)
}
